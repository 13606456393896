import React, { type ReactNode, useState } from 'react'
import { Button, ButtonGroup } from '@mui/material'
import { countries } from 'config/countries'
import type { HandleSubmitProps, MarketFormField, MarketFormFieldsProps, TabName } from './MarketForm.types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Codes from './Codes'
import Info from './Info'
import Forms from './Forms'
import { formatInTimeZone } from 'date-fns-tz'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'

const getTodayDate = (): Date => {
  const now = new Date()
  now.setHours(12, 0, 0, 0)
  return now
}

const getUserDate = (dateString: string): Date => {
  const serverTZ = process.env.REACT_APP_API_TIMEZONE
  const globalDate = serverTZ !== undefined ? formatInTimeZone(new Date(dateString), serverTZ, 'yyyy-MM-dd HH:mm') : new Date(dateString)
  return new Date(globalDate)
}

const renderDefaultValues = (defaultValue: boolean): Record<MarketFormField, { visible: boolean, required: boolean }> => ({
  idNumber: { visible: defaultValue, required: defaultValue },
  prizeSelection: { visible: defaultValue, required: defaultValue },
  buildingName: { visible: defaultValue, required: defaultValue },
  region: { visible: defaultValue, required: defaultValue },
  barcode: { visible: defaultValue, required: defaultValue },
  files: { visible: defaultValue, required: defaultValue },
  email: { visible: defaultValue, required: defaultValue },
  streetName: { visible: defaultValue, required: defaultValue },
  buildingNumber: { visible: defaultValue, required: defaultValue },
  apartmentNumber: { visible: defaultValue, required: defaultValue },
  city: { visible: defaultValue, required: defaultValue },
  country: { visible: defaultValue, required: defaultValue },
  province: { visible: defaultValue, required: defaultValue },
  postalCode: { visible: defaultValue, required: defaultValue },
  phoneNumber: { visible: defaultValue, required: defaultValue },
  comments: { visible: defaultValue, required: defaultValue },
  openQuestion: { visible: defaultValue, required: defaultValue },
  closedQuestion: { visible: defaultValue, required: defaultValue },
  dropdown: { visible: defaultValue, required: defaultValue },
  invoiceNumber: { visible: defaultValue, required: defaultValue }
})

const MarketFormFields = ({ isAddForm, languagesArray, continents, marketLanguages, onHandleSubmit, defaultValue }: MarketFormFieldsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabName>('info')
  const { t } = useTranslation()

  const defaultValues: HandleSubmitProps = {
    name: countries.find(el => el.label === defaultValue?.name) ?? { label: '', code: '' },
    description: defaultValue?.description ?? '',
    languages: marketLanguages?.map(el => ({ key: el.code, name: el.name, nativeName: el.nativeName })) ?? [],
    timeZone: defaultValue?.timeZone ?? '',
    entryValidation: defaultValue?.entryValidation ?? 0,
    entryValidationField: defaultValue?.entryValidationField ?? 'email',
    entryValidationType: defaultValue?.entryValidationType ?? 'NA',
    groupId: defaultValue?.groupId !== undefined ? defaultValue.groupId.toString() : '',
    holdingPageDate: defaultValue?.holdingPageDate !== undefined ? getUserDate(defaultValue.holdingPageDate) : getTodayDate(),
    promoLiveDate: defaultValue?.promoLiveDate !== undefined ? getUserDate(defaultValue.promoLiveDate) : getTodayDate(),
    promoEndDate: defaultValue?.promoEndDate !== undefined ? getUserDate(defaultValue.promoEndDate) : getTodayDate(),
    closedPageEndDate: defaultValue?.closedPageEndDate !== undefined ? getUserDate(defaultValue.closedPageEndDate) : getTodayDate(),
    personalDataDeletedBy: defaultValue?.personalDataDeletedBy !== undefined ? getUserDate(defaultValue.personalDataDeletedBy) : getTodayDate(),
    emailRecipient: defaultValue?.emailRecipient !== undefined ? defaultValue.emailRecipient : 'NA',
    flow: defaultValue?.flow !== undefined ? defaultValue.flow : '',
    prize: defaultValue?.prize !== undefined ? defaultValue.prize : '',
    verification: defaultValue?.verification !== undefined ? defaultValue.verification : 'email',
    receiptValidation: defaultValue?.receiptValidation !== undefined ? defaultValue.receiptValidation : 'auto',
    emailService: defaultValue?.emailService !== undefined ? defaultValue.emailService : false,
    codes: defaultValue?.codes !== undefined ? defaultValue.codes : [],
    forms: defaultValue?.forms !== undefined
      ? { ...renderDefaultValues(false), ...defaultValue.forms }
      : renderDefaultValues(false)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.object({ code: Yup.string(), label: Yup.string() }).required(t('forms.required', { field: t('market.name') }) ?? 'Required')
      .test({
        message: t('forms.required', { field: t('market.name') }) ?? 'Required',
        test: field => field?.code !== undefined && field?.code !== undefined && field?.code !== '' && field?.code !== ''
      }),
    flow: Yup.string().required(t('forms.required', { field: t('market.flow') }) ?? 'Required'),
    prize: Yup.string().required(t('forms.required', { field: t('market.prize') }) ?? 'Required'),
    entryValidation: Yup.string().required(t('forms.required', { field: t('market.prize') }) ?? 'Required'),
    entryValidationType: Yup.string().required(t('forms.required', { field: t('market.prize') }) ?? 'Required'),
    verification: Yup.string().required(t('forms.required', { field: t('market.verification') }) ?? 'Required'),
    receiptValidation: Yup.string().required(t('forms.required', { field: t('market.receiptValidation') }) ?? 'Required'),
    timeZone: Yup.string().required(t('forms.required', { field: t('market.timezone') }) ?? 'Required'),
    languages: Yup.array().test({
      message: 'You must select min 1 language per market',
      test: arr => arr?.length !== undefined ? arr?.length > 0 : false
    }),
    groupId: Yup.string().required(t('forms.required', { field: t('market.group') }) ?? 'Required'),
    holdingPageDate: Yup.string().required(t('forms.required', { field: t('market.holdingPageDate') }) ?? 'Required'),
    promoLiveDate: Yup.string().required(t('forms.required', { field: t('market.promoLiveDate') }) ?? 'Required'),
    promoEndDate: Yup.string().required(t('forms.required', { field: t('market.promoEndDate') }) ?? 'Required'),
    closedPageEndDate: Yup.string().required(t('forms.required', { field: t('market.closedPageEndDate') }) ?? 'Required'),
    personalDataDeletedBy: Yup.string().required(t('forms.required', { field: t('market.personalDataDeletedBy') }) ?? 'Required')
  })

  const { handleSubmit, control, trigger } = useForm<HandleSubmitProps>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  const renderTab = (): JSX.Element => {
    switch (selectedTab) {
      case 'info': {
        return <Info isAddForm={isAddForm} control={control} languagesArray={languagesArray} continents={continents} />
      }
      case 'form': {
        return (
          <Forms control={control} />
        )
      }
      case 'codes': {
        return <Codes control={control} />
      }
    }
  }

  const changeTab = async (name: TabName): Promise<void> => {
    await trigger().then(isValid => {
      if (isValid) {
        setSelectedTab(name)
      }
    })
  }

  const navBtns = (): ReactNode => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      {selectedTab === 'form' || selectedTab === 'codes'
        ? <Button
          type='button'
          variant='contained'
          sx={{ ml: 2 }}
          onClick={async () => { await changeTab(selectedTab === 'codes' ? 'form' : 'info') }}
        >
          {t('common.prev')}
        </Button>
        : null}
      {selectedTab === 'info' || selectedTab === 'form'
        ? <Button
          type='button'
          variant='contained'
          sx={{ ml: 2 }}
          onClick={async () => { await changeTab(selectedTab === 'info' ? 'form' : 'codes') }}
        >
          {t('common.next')}
        </Button>
        : null}
      {selectedTab === 'codes' || !isAddForm
        ? <Button
          type='submit'
          variant='contained'
          sx={{ ml: 2 }}
          startIcon={<DoneOutlinedIcon />}
        >
          {t('common.save')}
        </Button>
        : null}
    </Box>
  )

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
      <ButtonGroup sx={{ mb: 3 }}>
        <Button variant={selectedTab === 'info' ? 'contained' : 'outlined'} onClick={async () => { await changeTab('info') }}>
          {t('market.config')}
        </Button>
        <Button variant={selectedTab === 'form' ? 'contained' : 'outlined'} onClick={async () => { await changeTab('form') }}>
          {t('market.form')}
        </Button>
        <Button variant={selectedTab === 'codes' ? 'contained' : 'outlined'} onClick={async () => { await changeTab('codes') }}>
          {t('market.codes')}
        </Button>
      </ButtonGroup>
      {navBtns()}
      {renderTab()}
      {navBtns()}

    </form>
  )
}

export default MarketFormFields
